<template>
  <div>
    <ManageOrder />
  </div>
</template>
<script>
import ManageOrder from "@/components/Order/ManageOrder";
export default {
  components: {
    ManageOrder,
  },
  created() {
  },
};
</script>