<template>
  <v-form>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <h3>ดูรายการออเดอร์</h3>
      </v-col>

      <v-tabs>
        <v-tab v-for="item in tabitems" :key="item.key" @click="tab(item)">{{
          item.text
        }}</v-tab>
      </v-tabs>

      <v-col cols="12">
        <v-row>
          <v-col cols="4" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <!-- <v-col class="text-right">
            <v-btn @click="goToCreate()" color="primary" dark
              >เพิ่มหมวดหมู่</v-btn
            >
          </v-col> -->
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headersOrder"
            :items="items"
            :search="search"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:[`item.orderdate`]="{ item }">
              {{ new Date(item.orderdate).toLocaleDateString() }}
            </template>
            <!-- <template v-slot:[`item.orderType`]="{ item }">
              <v-col v-if="item.orderType = 'PICKUP'">รับเองที่ร้าน</v-col>
            </template> -->
            <template v-slot:[`item.status`]="{ item }">
              <v-chip v-if="item.status == 'รอการยืนยัน'" color="red" dark>
                {{ item.status }}</v-chip
              >
              <v-chip
                v-if="item.status == 'ยืนยันแล้วรอชำระเงิน'"
                color="brown"
                dark
              >
                {{ item.status }}</v-chip
              >
              <v-chip
                v-if="item.status == 'ชำระเงินแล้วรอจัดเตรียมสินค้า'"
                color="blue"
                dark
                >{{ item.status }}</v-chip
              >
              <v-chip
                v-if="item.status == 'ยืนยันสินค้าให้ลูกค้ามารับของ'"
                color="yellow"
                >{{ item.status }}</v-chip
              >
              <v-chip v-if="item.status == 'ถูกจัดส่งแล้ว'" color="green" dark
                ><v-icon>mdi-check-circle</v-icon>{{ item.status }}</v-chip
              >
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-row justify="center">
                <!-- <v-icon @click="viewOrder(item)">mdi-eye</v-icon> -->
                <v-icon @click="UpdateOrder(item)" class="mx-2"
                  >mdi-pencil</v-icon
                >
                <v-icon @click="DeleteOrder(item)">mdi-delete</v-icon>
              </v-row>
            </template>
          </v-data-table></v-card
        >
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { Decode, Encode } from "@/services";
export default {
  data() {
    return {
      headersOrder: [
        { text: "ลำดับ", value: "count", align: "center" },
        { text: "เลขออเดอร์", value: "orderNo", align: "center" },
        { text: "วันที่สั่ง", value: "orderdate", align: "center" },
        { text: "ประเภทการรับ", value: "orderType", align: "center" },
        { text: "ชื่อผู้สั่ง", value: "user.firstname", align: "center" },
        { text: "ราคา", value: "totalPrice", align: "center" },
        { text: "สถานะ", value: "status", align: "center" },
        { text: "ตัวเลือก", value: "action", align: "center" },
      ],
      items: [
        // { category: "promotion", description: "สินค้าจัดโปร" },
        // { category: "discount items", description: "สินค้าลดราคา" },
        // { category: "Shock price", description: "สินค้าราคาถูก" },
        // { category: "สินค้ามาใหม่", description: "สินค้ามาใหม่" },
      ],
      tabitems: [
        // {
        //   key: "menu1",
        //   text: "จัดการหมวดหมู่สินค้า",
        //   path: "/ManageCategory",
        //   name: "ManageCategory",
        //   icon: "menu",
        // },
        { text: "ทั้งหมด", key: "ทั้งหมด" },
        { text: "รอการยืนยัน", key: "รอการยืนยัน" },
        { text: "ยืนยันแล้วรอชำระเงิน", key: "ยืนยันแล้วรอชำระเงิน" },
        {
          text: "ยืนยันแล้วรอลูกค้ามารับของ",
          key: "ยืนยันสินค้าให้ลูกค้ามารับของ",
        },
        {
          text: "ชำระเงินแล้วรอจัดเตรียมสินค้า",
          key: "ชำระเงินแล้วรอจัดเตรียมสินค้า",
        },
        { text: "จัดส่งแล้ว", key: "ถูกจัดส่งแล้ว" },
      ],
      search: "",
      count: 0,
    };
  },
  created() {
    this.getAllOrder();
    setInterval(() => {
      this.getAllOrder();
    }, 10000);
  },
  methods: {
    async tab(val) {
      console.log(val.key);
      // /orders?status=รอการยืนยัน
      if (val.key == "ทั้งหมด") {
        this.getAllOrder();
      } else {
        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/orders?status=` + val.key
        );
        this.items = response.data.data;
        for (let i in this.items) {
          this.items[i].count = parseInt(i) + 1;
        }
        console.log("response", response.data.data);
      }
    },
    async getAllOrder() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/orders`
        // 'http://localhost:8081/categories/'
      );
      console.log("getAllOrder", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
    },
    UpdateOrder(val) {
      localStorage.setItem("orderdata", Encode.encode(val));
      this.$router.push("EditOrder");
    },
    async DeleteOrder(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var user = JSON.parse(Decode.decode(localStorage.getItem("user")));
          const auth = {
            headers: { Authorization: `Bearer ${user.token}` },
          };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/orders/` + val.id,
            auth
          );
          console.log(response);
          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllOrder();
        }
      });
    },
    goToCreate() {
      this.$router.push("createcategory");
    },
  },
};
</script>